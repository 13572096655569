import React from 'react'
//import GigsCard from '../Components/GigsCard/GigsCard';
import Hero from '../Components/Hero/Hero';
import Info from '../Components/Info/Info';
import Services from '../Components/Services/Services';
import Slider from '../Components/CatCard/Slide/Slide';
import Whatsapp from '../Components/Whatsapp/Whatsapp';
import Testimonial from '../Components/Testimonial/Testimonial';
import Package from '../Components/Package/Package';

const Land = () => {
  return (
    <>
    <Hero/>
    <Slider/>
    <Info/> 
    <Package/>
    {/* <GigsCard/>  */}
    <Services />
    <Testimonial/>
    <Whatsapp/>   
   </>
  )
}

export default Land