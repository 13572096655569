import React from 'react'
import './Footer.css'
import video from '../../Assets/hero.mp4'
import {FiChevronRight, FiSend} from 'react-icons/fi'
import {AiFillFacebook, AiFillInstagram, AiOutlineTwitter} from 'react-icons/ai'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdEmail} from 'react-icons/md'



const Footer = () => {
  return (
    <section className="footer">
       
      <div className="videoDiv">
      <div className="overlay"></div>
        <video src={video} loop autoPlay muted type="video/mp4"></video>
        </div>
        <div className="secContent container">
          <div className="contactDiv flex">
            <div className="text">
              <small>Keep In Touch</small>
              <h2>Subscribe Newsletter</h2>
            </div>
            <div className="inputDiv flex">
              <input type="text" placeholder='Enter Email Address'></input>
              <button className='btn flex' type='submit'>SEND<FiSend className="icon"/></button> 
        </div>
          </div>
          <div className="footerCard flex">
            <div className="footerIntro flex">
              <div className="logoDiv">
                <a href="https://www.anaxe.in" className='logo flex'>
                  ANAXE TECHNOLOGY
                </a>
              </div>
              <div className="footerParagraph">
                We are into IT services serving PAN India and across the globe. 
              </div>
              <div className="contact">
              <BsFillTelephoneFill/> +91-7009921260
              </div>
              <div className="contact">
              <MdEmail/> anaxetechnology@gmail.com
              </div>
              <div className="footerSocials flex">
                <AiOutlineTwitter className="icon"/>
                <AiFillInstagram className="icon"/>
                <AiFillFacebook className="icon"/>
              </div>
            </div>
            <div className="footerLinks grid">
              {/* Group One */}
              <div className="linkGroup">
                <span className="groupTitle">
                  Our Company
                </span>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Conditions
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Privacy
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  F&Q
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Reach Us
                </li>
              </div>

              {/* Group two */}
              <div className="linkGroup">
                <span className="groupTitle">
                  Our Services
                </span>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Web Apps
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Web Designing
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  SEO
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Graphics
                </li>
              </div>

              {/* Group Three */}
              <div className="linkGroup">
                <span className="groupTitle">
                  Our Company
                </span>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Services
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Graphics
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  SEO
                </li>
                <li className="footerList flex">
                  <FiChevronRight className='icon'/>
                  Apps
                </li>
              </div>
            </div>

            <div className="footerDiv flex">
              <small>ANAXE</small>
              <small>COPYRIGHTS RESERVED - 2023</small>
            </div>
          </div>
        </div>

        
      
    </section>
  )
}

export default Footer