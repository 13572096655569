import React from 'react'
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";


const Whatsapp = () => {
  return (
    <div>
       <WhatsAppWidget
			phoneNo="7009921260"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hi Team, is there any related service available ?"
			iconSize="40"
			iconColor="white"
			iconBgColor="hsl(74, 28%, 45%)"
			headerIcon="https://img.icons8.com/color/96/000000/user.png"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="hsl(74, 28%, 45%)"
			headerTitle="Ashish"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#999"
			btnBgColor="white"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>
       
    </div>
  )
}

export default Whatsapp