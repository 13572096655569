import './Hero.css'
import video from '../../Assets/herovideo.mp4'



const Hero = () => {


  return (
    <section className="hero">
      <div className="overlay"></div>
      <video src={video} muted autoPlay loop type="video/mp4"></video>

      <div className="homeContent container">
        <div className="textDiv">

          <span className="smallText">
            Anaxe Technology
          </span>
          <h1 className="homeTitle">
            Choose Your Service
          </h1>
        </div>
        <div className="popular">
          <span>Popular:</span>
          <button>Web Development</button>
          <button>Wordpress</button>
          <button>UI/UX</button>
          <button>App DEvelopment</button>
        </div>
             </div>
      
    </section>
  )
}

export default Hero