import React from 'react'
import "./Thankscomp.css"

const Thankscomp = () => {
  return (
   <section className='thanksMsg container section'>
    <div className="msgText">
        <h1>Thanks for the enquiry.</h1>
        <h3>We will get back with the best package</h3>
        <a href='/'>Back to Home</a>
    </div>
   </section>
  )
}

export default Thankscomp