export const gigs = [
    {
        id: 1,
        category: "webdesigning",
        avatar: "/images/informative.jpg",
        title: "Informative Website", 
        technology: "React.js & Next.js", 
        text: "Start From",    
        price: "$450",
        comment:
        "Single page Website (4-5 sections) with slider, contact form, map and social media integration, Google Services. 3 Revision, fully functional. delivery within 7 days.",
    },
    {
      id: 2,
      category: "webdesigning",
      avatar: "/images/informative1.jpg",
      title: "Informative Website", 
      technology: "Wordpress", 
      text: "Start From",    
      price: "$250",
      comment:
      "Single page Website (4-5 sections) with slider, contact form, map and social media integration, Google services. 3 Revision, fully functional. delivery within 5 days.",
  },
  {
    id: 3,
    category: "webdesigning",
    avatar: "/images/ecomm.jpg",
    title: "E-Commerce", 
    technology: "Wordpress", 
    text: "Start From",    
    price: "$700",
    comment:
    "Fully Responsive Ecommerce Website using Woocommerce, Payments gateways, SEO friendly, Speed Optimization, shipping methods & Tax Module, Coupens and much more.",
  },
  {
    id: 4,
    category: "webdesigning",
    avatar: "/images/blog.jpg",
    title: "Blogging Website", 
    technology: "Wordpress", 
    text: "Start From",    
    price: "$300",
    comment:
    "Highly professional and responsive design. SEO Friendly, Speed Optimizatin, Contact Form and much more. 2 Revision, Domain and Hosting Setup",
  },
  {
    id: 5,
    category: "webdesigning",
    avatar: "/images/travel.jpg",
    title: "Travel Agency Website", 
    technology: "Wordpress", 
    text: "Start From",    
    price: "$700",
    comment:
    "Fully Responsive Ecommerce Website, Payments gateways, SEO friendly, Dynamic Package Creation and booking, Speed Optimizatio, Coupens and much more.",
  },
  {
    id: 6,
    category: "logodesigning",
    avatar: "/images/logo.jpg",
    title: "Logo Design", 
    technology: "vector & Raster", 
    text: "Start From",    
    price: "$40",
    comment:
    "Hign Resolution JPG and PNG, EPS Format, PDF Format, Vector/Source file available, 2- Revisions, 4 days delivery. 24/7 Support",
  },
  {
    id: 7,
    category: "graphics",
    avatar: "/images/graphics.jpg",
    title: "Graphics Designing", 
    technology: "vector & Raster", 
    text: "Start From",    
    price: "$20",
    comment:
    "Flyer Design, Poster Design, Brochure, Banner, Header, Cover, Business Card, Book Cover, Magazine Cover, Letter Head and much more",
  },
  {
    id: 8,
    category: "api",
    avatar: "/images/restapi.jpg",
    title: "Rest APIs", 
    technology: "Express and Node JS", 
    text: "Start From",    
    price: "$700",
    comment:
    "APIs for E-Commerce Website or any other Web App using Node.Js, Express Js, MongoDB, Integrate Authentication using JWT, Custom Routes and much more",
  },
  {
    id: 9,
    category: "marketing",
    avatar: "/images/elementary.jpg",
    title: "SEO - Elementary Package", 
    technology: "Digital Marketing", 
    text: "Start From",    
    price: "$500",
    comment:
    "Graphics - 15, Post Content - 15, Facebook Ads - 3, Facebook Post - Yes, Instagram Ads - 3, Instagram Post - Yes, Google Ads - No, Linkedin Post - Yes",
  },
  {
    id: 10,
    category: "marketing",
    avatar: "/images/mainstream.jpg",
    title: "SEO - Mainstream Package", 
    technology: "Digital Marketing", 
    text: "Start From",    
    price: "$600",
    comment:
    "Graphics - 30, Post Content - 30, Facebook Ads - 5, Facebook Post - Yes, Instagram Ads - 5, Instagram Post - Yes, Google Ads - 1, Linkedin Post - Yes",
  },
  {
    id: 11,
    category: "marketing",
    avatar: "/images/supreme.jpg",
    title: "SEO - Supreme Package", 
    technology: "Digital Marketing", 
    text: "Start From",    
    price: "$700",
    comment:
    "Graphics - 30, Post Content - 30, Facebook Ads - 7, Facebook Post - Yes, Instagram Ads - 8, Instagram Post - Yes, Google Ads - 3, Linkedin Post - Yes",
  },
  
]

