import React from 'react'
import './Explore.css'
import GigsCard from '../../Components/GigsCard/GigsCard'

const explore = () => {
  return (
    <section className='explore section'>
        <GigsCard/>
    </section>
  )
}

export default explore