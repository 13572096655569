import TestimonialImg from '../../Assets/testimonial/feedback.jpg';
import Alex from '../../Assets/testimonial/alex.jpg';
import Linda from '../../Assets/testimonial/Linda.jpg';
import Kamal from '../../Assets/testimonial/kamal.png';
import AvatarGirl from '../../Assets/testimonial/avtar-girl.jpg';
import AvatarBoy from '../../Assets/testimonial/avtar-boy.jpg';

export const testimonial = {
    title: 'What people are saying about us',
    image: <TestimonialImg />,
    persons: [
      {
        avatar: <Linda />,
        name: 'Linda Acevedo',
        occupation: 'Owner at Loveklothe.com',
        message:
          '“Very nice job nicely put together in a timely matter”',
      },
      {
        avatar: <Alex />,
        name: 'Alex Vishwa',
        occupation: 'Developer',
        message:
          '“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”',
      },
      {
        avatar: <Kamal />,
        name: 'Kamal',
        occupation: 'CEO at Uniquedaddy.com',
        message:
          '“Anaxe Technology has done a many website for us. Thanks for the quality work”',
      },
      {
        avatar: <AvatarGirl />,
        name: 'Mamta',
        occupation: 'CEO at Varenyumtravels.com',
        message:
          '“I am very happy with work. Thanks Anaxe Technology ”',
      },
      {
        avatar: <AvatarGirl />,
        name: 'Manjeet',
        occupation: 'Manager at Sunglow',
        message:
          '“Perfect work on the time.”',
      },
    ],
  };