import React from 'react'
import './Services.css'
import { ImMobile } from "react-icons/im";
import { MdWeb } from "react-icons/md";
import { MdWebStories } from "react-icons/md";
import { MdDeveloperBoard } from "react-icons/md";
import { IoIosImage } from "react-icons/io";
import { FaAtlassian } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { ImDatabase } from "react-icons/im";





const Services = () => {
  return (
    <section class="service container section" id="service">
         <div className="serviceTitle">
        <h3 className='sctitle'>
          Services
        </h3>
      </div>
      <div class="service-box-container">

        <div class="box">
        <MdWeb className="icon"/>
            <h3>UI/UX</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">        
            <MdWebStories className="icon"/>
            <h3>Web Designing</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <MdDeveloperBoard className="icon"/>
            <h3>Web Development</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <ImMobile className="icon"/>
            <h3>App Development</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <IoIosImage className="icon"/>
            <h3>Graphics</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <FaAtlassian className="icon"/>
            <h3>Logo Designing</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <SiMarketo className="icon"/>
            <h3>Digital Marketing</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

        <div class="box">
        <ImDatabase className="icon"/>
            <h3>Database Design</h3>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro, suscipit.</p> */}
        </div>

    </div>
    </section>
  )
}

export default Services