import React from 'react'
import Thankscomp from '../Components/Thankscomp/Thankscomp'

const Thanks = () => {
  return (
    <div>
        <Thankscomp />
    </div>
  )
}

export default Thanks