import React from 'react'
import { testimonial } from './data'
import './Testimonial.css'
import TestimonialSlider from '../TestimonialSlider/TestimonialSlider';
import vid from '../../Assets/hero.mp4'
const Testimonial = () => {
    const { image, title } = testimonial;
  return (
    <section className='section'>
      <div className='container'>
        <div className='test-items'>
          <div className='left'>
            {/* title */}
            <h2 className='title'>{title}</h2>
            {/* slider */}
            <TestimonialSlider />
          </div>
          {/* image */}
          <div className='right'>
            {/* <img src={image.type} alt='' /> */}
            <video src={vid} loop autoPlay muted></video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial