import React, {useState} from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom';
import { AiFillCloseCircle } from 'react-icons/ai';
import { TbGridDots } from 'react-icons/tb';
import Phonecall from '../Phonecall/Phonecall';
const Navbar = () => {

  const [active, setActive] = useState('navBar')
  const [open, setOpen] = useState(false);
  // Function to toggle navBar
  const showNav = ()=>{
    setActive('navBar activeNavbar')
  }
   // Function to remove navBar
   const removeNavbar = ()=>{
    setActive('navBar')
  }
  return (
    <section className="navBarSection">
        <header className="header flex">
          
            <div className="logoDiv">
                <Link to="/" className="logo">
                <h1> Anaxe</h1></Link>  
            </div>

            <div className={active}>
              <ul className="navList flex">
                <li className="navItem">
                  <Link to="/" className="navLink">Home</Link>
                </li>

                <li className="navItem">
                  <Link to ="/explore" className="navLink">Explore</Link>
                </li>

                <li className="navItem">
                  <Link to="/reachus" className="navLink">Reach Us</Link>
                </li>      
                  <Phonecall/>
                </ul>  
                <div onClick={removeNavbar} className="closeNavbar">
                <AiFillCloseCircle className='icon'/>
              </div>          
            </div>  
            <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon"/>
        </div>    
        </header>
    </section>
  )
}

export default Navbar