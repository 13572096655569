import React from 'react'
import "./TestimonialSlider.css"
// import swiper react components
import { Swiper, SwiperSlide } from 'swiper/react';
// import swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Navigation } from 'swiper';
// import data
import { testimonial } from '../Testimonial/data';

const TestimonialSlider = () => {
  return (
    <Swiper
    className='testimonialSlider'
    loop={true}
    navigation={false}
    autoplay={true}
    modules={[Navigation, Autoplay]}
  >
    {testimonial.persons.map((person, index) => {
      const { avatar, name, occupation, message } = person;
      return (
        <SwiperSlide key={index}>
          <div className='outer-container'>
            <div className='item-container'>
              {/* avatar */}
              <img className="img" src={avatar.type} alt='' />
              <div>
                <div className='name'>{name}</div>
                <div className='occupation'>{occupation}</div>
              </div>
            </div>
            {/* text */}
            <div className='message'>{message}</div>
          </div>
        </SwiperSlide>
      );
    })}
  </Swiper>
  )
}

export default TestimonialSlider