import React from 'react'
import './Info.css'
import { GoCheck } from 'react-icons/go';
import vid from '../../Assets/hero.mp4'

const Info = () => {
  return (
    <section className=" info container section" id="info">
        <div className="infoTitle">
        <h3 className='infotitle'>
          Features
        </h3>
      </div>
      <div className="infoContent">
        <div className="infoContainer">
            <div className="item">
                <h1>Quality products at one place</h1>
                <div className="heading">
                <GoCheck className='icon'/> The best for every budget
                </div>
                <p>Find high-quality services at every price point. No hourly rates. Project based price</p>

                <div className="heading">
                <GoCheck className='icon'/> Quality work done quickly
                </div>
                <p>Get a right team to begin working on your project within minutes</p>

                <div className="heading">
                <GoCheck className='icon'/> Protected payments
                </div>
                <p>Easy Payments and Payment is 100% secure.</p>

                <div className="heading">
                <GoCheck className='icon'/> EMI 
                </div>
                <p>Customer can pay in EMI </p>
            </div>
            </div>
            {/* <div className="item">
                <video src={vid} controls></video>
            </div> */}
             <div className="infoContainer">
            <div className="item">
                <div className="heading">
                <GoCheck className='icon'/> Top-Notch Technologies
                </div>
                <p>We building our websites on the Latest Technologies</p>

                <div className="heading">
                <GoCheck className='icon'/> Life Time Maintainance 
                </div>
                <p>Get your work done for the life time maintainance</p>

                <div className="heading">
                <GoCheck className='icon'/> Customer Satisfaction
                </div>
                <p>Customer satisfaction is our first priority.</p>

                <div className="heading">
                <GoCheck className='icon'/> 24/7 support
                </div>
                <p>24/7 customer support </p>
            </div>
            </div>
      </div>
    </section>
  )
}

export default Info