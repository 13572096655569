import React from 'react'
import './GigsCard.css'
import { GrTechnology } from 'react-icons/gr'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { gigs } from './data'
import { Link } from 'react-router-dom'
import Phonecall from '../Phonecall/Phonecall'
//import { Link } from 'react-router-dom'

const Gigs = () => {
  return (
    <section className='gigscard container section' id="packages">

      <div className="secTitle">
        <h3 className='title'>
         Popular Packages
        </h3>
      </div>
       
      <div className="secContent grid">
        {
          gigs.map(({ id, avatar, title, text, technology, price, comment }) => {
            return (
              <div key={id} className="singlePackage">
                <div className="imageDiv">
                  <img src={avatar} alt={title} />
                </div>
                <div className="cardInfo">
                  <h4 className='cardTitle'>{title}</h4>
                  <span className="continent flex">
                    <GrTechnology className='icon' />
                    <span className='name'>{technology}</span>
                  </span>
                  <div className='fees flex'>
                    <div className="startfrom">
                      <span>{text}</span>
                    </div>
                    <div className="price">
                      <h5>{price}</h5>
                    </div>
                  </div>
                  <div className="desc">
                    <p>{comment}</p>
                  </div>
                 
                    {/* <button className='btn flex'>
                    <Link to ="/gig/123">Detail</Link>
                     <BsFillTelephoneFill  />
                    </button> */}
                   
                    <Phonecall/>
                   
                </div>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default Gigs
