import React from 'react'
import "./CatCard.css"
import {Link} from 'react-router-dom'

const CatCard = ({item}) => {
  return (
    <section className='catCard section'>
      <div className='catitem'>
       <Link to = {`/article/${item.link}`}>
        <img src={item.img} alt=""/>
        </Link>
        <span className='title'>{item.title}</span>
        {/* <span className='desc'>{item.desc}</span> */}
        </div>
    </section>
  )
}

export default CatCard