import React from 'react'
import './Reachus.css'
import { ImLocation2 } from 'react-icons/im';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { SiMinutemailer } from 'react-icons/si';
import { FaGlobeAfrica } from 'react-icons/fa';

import { useRef } from 'react';
import emailjs from '@emailjs/browser';

import image from '../../Assets/socialmedia.jpg'
const Reachus = () => {

  const Form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_byfh5jl', 'template_ysbk19b', Form.current, 'yBQRfvih8-2b24ZNF')
      .then((result) => {
       
        console.log(result.text);
        window.open("/Thanks", "_self");
        //alert('thank you for your message');
       
        Form.current.reset();
       
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <section className='reachus container section'>
      <div className='reach-title'>
        <h1>Reach Us</h1>
      </div>

      <div className='contact-detail'>

        <div className='reach-info'>
        <div className='iconsetting'> <ImLocation2 className='reach-icon'/></div>       
        <div className='reach-head'>         
        <h3 className='con-title'>Address:</h3>
        <div className='address'>
        <span>Office 1: Punjab, India. 146023.</span>
        <br/>
        <span>Office 2: Etobicoke, Ontario, Canada.</span>
        </div>
        </div>
        
        </div>
       

        <div className='reach-info'>
        <div className='iconsetting'><BsFillTelephoneFill className='reach-icon'/></div>
        <div className='reach-head'>        
        <h3 className='con-title'>Phone:</h3>
        <span>+91-7009921260</span></div>
        </div>
       
        <div className='reach-info'>
        <div className='iconsetting'><SiMinutemailer className='reach-icon'/></div> 
        <div className='reach-head'>
        <h3 className='con-title'>Email:</h3>
        <span>anaxetechnology@gmail.com</span></div>
        </div>
       
        <div className='reach-info'>
        <div className='iconsetting'><FaGlobeAfrica className='reach-icon'/></div>
        <div className='reach-head'>          
          <h3 className='con-title'>Website:</h3>
        <span>www.anaxe.in</span></div>
        </div>
       
      </div>

      <div className='item-container'>
        <div className='item-left'>
           

            <form ref={Form} onSubmit={sendEmail}>
          <div className="cardDiv grid">
            <div className="nameInput">
              <label htmlFor="name"> Enter Your Name </label>
              <div className="input flex">
                <input type="text"
                  placeholder="Enter Your Name" required name="from_name" />
              </div>
            </div>

            <div className="emailInput">
              <label htmlFor="email"> Enter Your E-Mail </label>
              <div className="input flex">
                <input type="email"
                  placeholder="Enter Your Email" required name="from_email" />
              </div>
            </div>

            <div className="phoneInput">
              <label htmlFor="name"> Enter Your Phone No. </label>
              <div className="input flex">
                <input type="tel"
                  placeholder="Enter Your Phone No." required name="from_phone" />
              </div>
            </div>

            <div className="MessageInput">
              <label htmlFor="name"> Enter Your Message </label>
              <div className="inputtextarea flex">
                <textarea type="text" rows={5}
                  placeholder="Enter Your Message" required name="subject" />
              </div>
            </div>

            <div className="submitButton">
              <button className="btn" type="submit"><span>Submit</span></button>            
            </div>
          </div>
        </form>
        </div>

        <div className='item-right'>
         <img src={image} alt=""/>
        </div>
      </div>
    </section>
  )
}

export default Reachus