import './App.css';
import React from 'react'
import Land from './pages/Land';
import Article from './pages/article/Article'
//import Gigs from './pages/gigs/Gigs'
import Reachus from './pages/reachus/Reachus'
import Explore from './pages/explore/Explore'
// import MyGigs from './pages/myGigs/MyGigs';
import Layout from './Components/Layout/Layout';
import Thanks from './pages/Thanks';
// import Orders from './pages/orders/Orders';
// import Messages from './pages/messages/Messages';
// import Message from './pages/message/Message';
// import Add from './pages/add/Add';
// import Login from './pages/login/Login';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Land /> }/>
          <Route path="/reachus" element={<Reachus/>}/>
          <Route path="/thanks" element={<Thanks /> }/> 
          <Route path="/article/" element={<Article />}/>
          <Route path="/article/:link" element={<Article />}/>
          {/* <Route path="/gigs" element={<Gigs/> }/>
          <Route path="/:id" element={<Gigs/> }/> */}
          <Route path="/explore" element={<Explore/> }/>
          {/* <Route path="/gig/:id" element={<Gig/> }/>
          <Route path="/mygigs" element={<MyGigs/> }/>   
          <Route path="/orders" element={<Orders/> }/>
          <Route path="/messages" element={<Messages/> }/>
          <Route path="/message/:id" element={<Message/> }/>
          <Route path="/add" element={<Add/> }/> */}
          {/* <Route path="/login" element={<Login/> }/> */}

       </Route>
      </Routes>
    </BrowserRouter>
    </>
  )
}

export default App
