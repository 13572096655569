export const cards = [
    {
      id: 1,
      title: "Logo Designing",
      desc: "A logo is a visual representation of a brand's identity. Its primary purpose is to create a memorable and recognizable symbol that communicates the essence of a business, product, or service. A well-designed logo can help establish credibility, foster brand loyalty, and make a strong first impression. The choice of fonts (typefaces) plays a significant role in logo design. Different fonts evoke distinct emotions and can convey a brand's personality. Serif fonts often convey tradition and sophistication, while sans-serif fonts tend to feel modern and clean. Script fonts can give a sense of elegance or informality. Visual elements like icons, symbols, or illustrations can be powerful components of a logo. These elements should be relevant to the brand and convey its values or services. A simple, memorable icon can often make a logo more recognizable.",
      img: "/images/logoo.jpg",
      content: ["Our price for logo design is start from low as $15", "Two days delivery time","Supported file formats AI, EPS, JPG, PDF, PNG, PSD, SVG","There are 3 changes we provide with the basic package","To Have a Well-Researched, Professional Logo","Our Logo Design Make a Good First Impression on Your Customers"],
      link: "logodesigning"
    },
    {
      id: 2,
      title: "AI Artist",
      desc: "AI is an essential technology in the modern world that is built upon the assumption that you can“Let the Machine Play Your Role”. For example, production, recycling, development, design, transportation, infrastructure production and more can be controlled by machines instead of humans. It emphasizes the development of smart and intelligent machines that act like humans. For example, an AI-enabled machine can help a doctor identify diseases from an image, it can control both air and ground traffic, and so on. It boosts user-experience by creating strong human connections by improving social listening, data analysis, and facial recognition. This allows companies to know their customers' behavior at an advanced and intimate level. It’s inbuilt with virtual robots and innovative technology to provide efficient functioning just like humans. For example, the Amazon Echo, popularly called Alexa, a virtual assistant developed by Amazon.",
      img: "/images/ai.jpg",
      content: ["The primary use of AI in chatbots is to increase business sales with better reply predictions.","The sites powered with AI can identify the customer's needs in a better way.","Rise In Demand For Voice-Based Search","AI applies Machine Learning, and both collaborate to make marketing predictions."],
      link: "ai"
    },
    {
      id: 3,
      title: "Graphics",
      desc: "Graphic design is a craft where professionals create visual content to communicate messages. By applying visual hierarchy and page layout techniques, designers use typography and pictures to meet users’ specific needs and focus on the logic of displaying elements in interactive designs, to optimize the user experience. Graphic design is essential to communicating with existing and future clients. It provides the visual assets that can be used for all of your marketing campaigns. These include your brand’s logo, user interface, images, typography, navigation, and other elements. Web development creates the online experience for your site’s visitors through the use of copywriting, search engine optimization (SEO), site structure, coding, link profile, and other aspects that make it work for your legal practice. Strategic website development makes your site’s content responsive across all devices while ensuring that users access your content as easily as possible.",
      img: "/images/graphiccs.jpg",
      content: ["Visual appeal: Graphics are important in web development because they can help to improve the overall look and feel of a website.","Coveys information: Graphics can also be used to convey information or messages to website visitors","Adds credibility to the website: Effective Graphic design brings professionalism to the website.","Enhances website usability: Graphics encourage viewers to stay on the site more."," It enhances the usability of the website as well as makes it easier to understand and navigate.","Improves site’s rankings: It also makes it easy for the users to find the information soon and improves the site engagement."],
      link: "graphics"
    },
    {
      id: 4,
      title: "Digital Marketing",
      desc: "If you have a computer or a smartphone, as many of us do, you’ve experienced digital marketing. It could be an email in your inbox, a search result when using Google, an ad on Facebook, a text message sent to your phone, or a post by an influencer on Instagram. As you can see, internet marketing is an expansive field with many areas of specialization. The core forms of digital marketing, however, are:",
      img: "/images/digital.jpg",
      content: ["Search Engine Optimization (SEO)","Paid Social and Paid Search Advertising","Email Marketing:","Content Marketing:","Affiliate Marketing:","Mobile Marketing:","Social Media Marketing:"],
      link: "marketing"
    },
    {
      id: 5,
      title: "Website Designing",
      desc: "Web designing is the process of planning, conceptualizing, and implementing the plan for designing a website in a way that is functional and offers a good user experience. User experience is central to the web designing process. Websites have an array of elements presented in ways that make them easy to navigate. Web designing essentially involves working on every attribute of the website that people interact with, so that the website is simple and efficient, allows users to quickly find the information they need, and looks visually pleasing. All these factors, when combined, decide how well the website is designed.",
      img: "/images/websitedes.jpg",
      content: ["Responsive design","Custom asset design","Map Integration","Contact Form","API Integration"],
      link: "webdesigning"
    },
    {
      id: 6,
      title: "UI/UX",
      desc: "UI/UX design aims to create a positive user experience that encourages customers to stick with a brand or product. While a UX Designer decides how the user interface works, the UI designer decides how the user interface looks. This article is a comprehensive guide on how to become a UI UX Designer and discusses other relevant topics related to the UI UX field. To succeed in today's market, organizations must shift from product-centric to customer-centric thinking. They must use data and UI/UX design principles to understand consumer demands rather than launching what they assume users would desire.",
      img: "/images/uiuxx.jpg",
      content: ["MockUp available in reasonable price","Software using for mockups is Figma and Adove XD"],
      link: "uiux"
    },
    {
      id: 7,
      title: "Social Media",
      desc: "Social media facilitates the sharing of ideas and information through virtual networks. From Facebook and Instagram to X platform (formerly Twitter) and YouTube, social media covers a broad universe of apps and platforms that allow users to share content, interact online, and build communities. More than 4.7 billion people use social media, equal to roughly 60% of the world’s population.",
      img: "/images/socialmedia.jpg",
      content: ["handling Facebook account","handling Youtube","Handling Instagram","Handling Twitter","LinkedIn & More"],
      link: "socialmediadesign"
    },
    {
      id: 8,
      title: "Mobile Apps",
      desc: "Mobile app development is designing and building software applications that can run on a mobile device. Typically, a mobile app makes use of a network connection to work with remote computing resources. Thus, the mobile app development process involves building installable software bundles, implementing backend services such as data access with an API, and testing the application on target devices.",
      img: "/images/mobileapps.jpg",
      content: ["Mobile Apps for Android"," Mobile Apps for IOS"],
      link: "appdevelopment"
    },
    {
      id: 9,
      title: "Database",
      desc: "Database development is designing, creating a database or data model, and analyzing requirements and their intents as raw data. Database development aims to create a structure that will allow for the efficient storage and retrieval of data. Being a blueprint for the database, it should meet the needs of business. Database development includes the design of the table structure, the creation of the indexes, and the development of the procedures and triggers. So, it is an essential part of every project. The software that businesses and governments use for storing data has to support all the features you need. For storing and handling massive volumes of data, relational databases are usually the best option.",
      img: "/images/database.jpg",
      content: ["Designing database for Websites and Apps", "Building APIs"],
      link: "databasedesign"
    },
  ];