import React from 'react'
import './Phonecall.css'
import { BsFillTelephoneFill } from 'react-icons/bs'
const Phonecall = () => {
  return (
   
    <button className="phonecall">
      <a className='link' href="tel:+917009921260">Call Now</a>
      <BsFillTelephoneFill  />
    </button>
  
  )
}

export default Phonecall