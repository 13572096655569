import React from 'react'
import "./Package.css"
import { Link } from 'react-router-dom'

const Package = () => {
  return (
    <section className='package container section'>
         <div className="packageTitle">
        <h3 className='pctitle'>
          Packages
        </h3>
      </div>

      <div className="package-container">
        <div className='package-items'>
            <h1 className='pac-title'>Our Best Selling Packages</h1>
            <h4 className='sub-title'>Anaxe Technology where you're excited 
                to work with and take your career or business to new heights.</h4>
            <><hr/></>
                {/* <div className='lower-section'>
                    <h3 className='lower-text'>Tell us Your Idea which Comes Into Reality</h3>
                    <h3 className='lower-text'>Choose Our Ready Made Packages</h3>
                </div> */}
                <Link to="/explore">
                <div className='explore-button'>
                     <button>Explore</button>
           
        </div>  </Link>    
        </div>
          
        
      </div>
    </section>
  )
}

export default Package