import React, { useState, useEffect } from 'react'
import './Article.css'
import { useParams } from 'react-router'
import {cards} from '../../Components/CatCard/Slide/data'
import { GiCheckMark } from 'react-icons/gi';

const Article = () => {

    const {link} = useParams();
    const [article, setArticle] = useState(null);

    useEffect(()=>{
        const article = cards.find((article) => article.link === link);
        if (article) {
        setArticle(article);
        }
        
    },[link]);
    console.log(article);

  return (    
     <section className='article container section'>
        {article ? (  <div>
      <div className='article-title'>
        <h1>{article.title}</h1>
      </div>
      <div className='article-head'>
            <div className='short-desc'>{article.desc}</div>
            <img className='article-img' src={article.img} alt=""/>
      </div>

      <div className='article-longdesc'>
      
        {article.content.map((conten)=>(
            <div className='conten' key={conten}>
             
              <p><GiCheckMark className="check"/>{conten}</p>
          </div>
        ))}
      
        {/* <p>{article.content}</p> */}
      </div>
        </div>) :<div></div>}
      
    </section>
    
   
    
  )
}

export default Article