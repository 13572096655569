import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import {cards} from './data'

import "./Slide.css";

// import required modules
import { FreeMode, Pagination, Autoplay, A11y } from "swiper";
import Catcard from '../CatCard';
const Slide = () => {
  return (
    
    
    <div className="slider section container">
      <div className="categoryTitle">
        <h3 className='cttitle'>
          Categories
        </h3>
      </div>
      {/* <div className="slidercontainer"> */}
      <Swiper

      breakpoints={{
        360: {
          width: 360,
          slidesPerView: 2,
        },
        390: {
          width: 390,
          slidesPerView: 3,
        },
        768: {
          width: 768,
          slidesPerView: 4,
        },
        1024: {
          width: 1024,
          slidesPerView: 4,
        },
        1440: {
          width: 1440,
          slidesPerView: 5,
        },
      }}

      slidesPerView={4}
      spaceBetween={2}
      freeMode={true}
      pagination={{
        clickable: true,
      }}
      loop={true}
      autoplay={{
      delay: 3000,
      disableOnInteraction: false
    }}
      modules={[FreeMode, Pagination, Autoplay, A11y]}
      className="mySwiper"
    >
         {cards.map(card=>(
          <SwiperSlide>
            
          <Catcard item={card} key={card.id}/>
          
          </SwiperSlide>
         ))}
      </Swiper>
      {/* </div> */}
    </div>
    
    
  )
}

export default Slide